import './Arrow.css';

import MakeAppointment from "components/MakeAppointment";

export default function ModelPage_Arrow(props){
    
    return(
        <div className="ModelPage_Arrow">
            <div className="container">
                {
                    props.data.contents.map((elem, index)=>{
                        let bg = "#6b7d5c";
                        let bgTriangle = "#59684B";
                        if(index == 1){
                            bg = "#59684B";
                            bgTriangle = "#6b7d5c";
                        }
                        return(
                            <div className="div_arrow" key={ index }>
                                {
                                    index == 0 ? 
                                    <div className="div_triangle">
                                        <div className="top" style={ { borderRightColor: bg } } />
                                        <div className="bottom" style={ { borderRightColor: bg } } />
                                    </div> : null
                                }
                                <div className="div_text" style={ { background: bg } }>
                                    <div className="title">{ elem.title }</div>
                                    <div className="text" dangerouslySetInnerHTML={ { __html: elem.text.replaceAll('&#34;', '"') } } />
                                </div>
                                <div className="div_triangle">
                                    <div className="show_right" style={ { background: index == 2 ? "transparent" : bgTriangle, borderLeftColor: bg } } />
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}