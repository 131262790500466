import React, { useState, useEffect } from "react";

import { SvgClose } from "components/SvgFile";

import './Text.css';

import { GetListPag } from "interface/Page";
import { GetDataPage } from "interface/Data";
import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

export default function PopUP_Text(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ title, setTitle ]         = useState('');
    const [ text, setText ]           = useState('');

    function InitialData(type){
        const newData = GetDataPage('config');
        return newData[type]
    }

    function ClosePopUp(){
        setText('');
        SetModalState('TextFooter', false);
    }

    useEffect(()=>{
        RegisterModalData('TextFooter', setModaldata);
        RegisterModalObserver('TextFooter', setShowPopUp);
    }, []);
    
    useEffect(()=>{
        if(showPopUp == true){
            setTitle(modalData.title);
            setText(InitialData(modalData.type));
            document.body.style.overflow = "hidden";

        }else {
            document.body.style.overflow = null;
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all PopUp_TextFooter">
                        <div className="div_data type_div">
                            <div className="popup_title">{ title }</div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div className="" dangerouslySetInnerHTML={ { __html: text !="" ? text.replaceAll('&#34;', '"') : "" } } />
                            </div>
                        </div>
                    </div>
                    <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
