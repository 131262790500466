import { useState, useEffect } from "react";

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { SvgArrow_3 } from "components/SvgFile";

import Menu from "components/Menu";
import Footer from "components/Footer";

import ModelPage_Home from "components/ModelPage/Home";
import ModelPage_Arrow from "components/ModelPage/Arrow";
import ModelPage_Questions from "components/ModelPage/Questions";
import ModelPage_TextTitle from "components/ModelPage/TextTitle";
import ModelPage_CardPhoto from "components/ModelPage/CardPhoto";
import ModelPage_TextPhoto from "components/ModelPage/TextPhoto";
import ModelPage_CardIcons from "components/ModelPage/CardIcons";
import ModelPage_TextSimple from "components/ModelPage/TextSimple";

import PopUP_Health from "components/PopUp/Health";
import PopUP_ReturnResponse from "components/PopUp/ReturnResponse";
import PopUP_Text from "components/PopUp/Text";

export default function App() {
    const [ loading, setLoading ]         = useState(false);
    const [ idioma, setIdioma ]           = useState(GetListPag('idioma'));
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage')); 

    const [ dataPage, setDataPage ] = useState(InitialData());   

    function InitialData(){
        const newData = GetDataPage('site').filter(item => item.idioma == idioma);
        return newData
    }

    function CkickPage(value){      
        switch (value) {
            case "index":
                    SetListPag('currentPage', value);   
                break;
        
            default: 
                    SetListPag('remuve', 'All');
                    SetListPag('currentPage', 'index');   
                break;
        }
    }

    function CurrentPage(type, data, index){
        switch (type) {
            case "Home":
                return <ModelPage_Home origin={ type } data={ data } CkickPage={ CkickPage } setLoading={ setLoading } key={ index } index={ index } />;

            case "Texto":
                return <ModelPage_TextSimple origin={ type } data={ data } CkickPage={ CkickPage } setLoading={ setLoading } key={ index } index={ index } />;

            case "Texto com título em destaque":
                return <ModelPage_TextTitle origin={ type } data={ data } CkickPage={ CkickPage } setLoading={ setLoading } key={ index } index={ index } />;

            case "Texto com imagem":
                return <ModelPage_TextPhoto origin={ type } data={ data } CkickPage={ CkickPage } setLoading={ setLoading } key={ index } index={ index } />;
            
            case "Seta":
                return <ModelPage_Arrow origin={ type } data={ data } CkickPage={ CkickPage } setLoading={ setLoading } key={ index } index={ index } />;

            case "Card com icones":
                return <ModelPage_CardIcons origin={ type } data={ data } CkickPage={ CkickPage } setLoading={ setLoading } key={ index } index={ index } />;

            case "Card com imagem":
                return <ModelPage_CardPhoto origin={ type } data={ data } CkickPage={ CkickPage } setLoading={ setLoading } key={ index } index={ index } />;
        }
    }

    function ReturnIndex(){
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterListPag('currentPage', setCurrentPage);
        
        window.addEventListener("scroll", ()=>{
            if(window.scrollY >= 60){
                document.getElementById('return').classList.add('return_index');
                document.getElementById('return').classList.remove('return_close');
            }else {
                document.getElementById('return').classList.add('return_close');
                document.getElementById('return').classList.remove('return_index');
            }
        });
    }, []);

    useEffect(()=>{
    }, [currentPage]);

    useEffect(()=>{
        if(loading == true){
            document.body.style.overflow = "hidden";
        }else {
            document.body.style.overflow = null;
        }
    }, [loading]);

    useEffect(()=>{
        setIdioma(GetListPag('idioma'));
        setDataPage(InitialData())
    }, [idioma]);

    return (
        <>
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                 <div className="hourglassBackground">
                    <div className="hourglassContainer">
                        <div className="hourglassCurves" />
                        <div className="hourglassCapTop" />
                        <div className="hourglassGlassTop" />
                        <div className="hourglassSand" />
                        <div className="hourglassSandStream" />
                        <div className="hourglassCapBottom" />
                        <div className="hourglassGlass" />
                    </div>
                </div>
            </div>
            
            <div className="whatsapp">
                <a href="https://api.whatsapp.com/send?phone=351965056763" target="_blank">
                    <img alt="Whatsapp" src="./assets/whatsapp.svg" className="icon_whatsapp" />
                </a>
            </div>

            <div className="return_close" id="return" onClick={ ()=>{ ReturnIndex() } }>
                <SvgArrow_3 className="icon_whatsapp" color="rgb(255 181 0)" />
            </div>

            <Menu CkickPage={ CkickPage } setLoading={ setLoading } />
            
            {
                dataPage.map((elem, index)=>{
                    return CurrentPage(elem.type, elem, index);
                })
            }

            <ModelPage_Questions CkickPage={ CkickPage } setLoading={ setLoading } />

            <Footer CkickPage={ CkickPage } setLoading={ setLoading } />

            <PopUP_Health />
            <PopUP_ReturnResponse />
            <PopUP_Text />
        </>
    );
}