
let DataPage = {
    "questions"        : [],
    "site"             : [],
    "questionnaire"    : [],
    "unavailable_time" : [],
    "config"           : {}
};

let NotifyDataPage = {
    "questions"        : [],
    "site"             : [],
    "questionnaire"    : [],
    "unavailable_time" : [],
    "config"           : []
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["questions"].forEach(element => {
        element(value['questions']);
    });
    NotifyDataPage["site"].forEach(element => {
        element(value['site']);
    });
    NotifyDataPage["questionnaire"].forEach(element => {
        element(value['questionnaire']);
    });
    NotifyDataPage["unavailable_time"].forEach(element => {
        element(value['unavailable_time']);
    });
    NotifyDataPage["config"].forEach(element => {
        element(value['config']);
    });
}

export function SetListDataSingle(key, value) {
    
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}
