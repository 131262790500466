import { useState, useEffect } from "react";

import './Footer.css';

import { SetModalData, SetModalState } from "interface/PopUp";

export default function Footer(props){

    function OpenData(title, type){
        SetModalData('TextFooter', { "title": title, "type": type });
        SetModalState('TextFooter', true)
    }
    
    return(
        <footer>
            <div className="div_logo">
                <img alt="logotipo" src="./assets/footer_01.png" className="logo" />
            </div>
            <div className="div_text">
                <div onClick={ ()=>{ OpenData('Termos e condições', 'terms_conditions') } }>Termos e condições</div>
                <div onClick={ ()=>{ OpenData('Politica de privacidade', 'privacy_policy') } }>Politica de privacidade</div>
                <div>
                    <a href="https://www.livroreclamacoes.pt/Inicio/" target="_blank">
                        Link para livro de reclamações
                    </a>
                </div>
            </div>
            <div className="div_ers">
                <img alt="logotipo" src="./assets/footer_02.png" className="ers" />
            </div>
        </footer>
    )
}