import { useState, useEffect } from 'react';

import './TextTitle.css';

import MakeAppointment from "components/MakeAppointment";

import { GetListPag } from 'interface/Page';
import { GetDataPage } from 'interface/Data';

export default function ModelPage_TextTitle(props){

    const [ spaceTop, setSpaceTop ]       = useState('');
    const [ spaceBottom, setSpaceBottom ] = useState('');

    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(InitialData());

    function InitialData(){
        const newData = GetDataPage('site').filter(item => item.idioma == idioma);
        return newData
    }

    useEffect(()=>{
        if(props.index != 0){            
            if(dataPage[props.index - 1]['type'] == 'Seta'){
                setSpaceTop('Space_Arrow_bottom');
            }
            if(dataPage[props.index + 1]){
                if(dataPage[props.index + 1]['type'] == 'Seta'){
                    setSpaceBottom('Space_Arrow_top');
                }
            }
        }
    }, []);

    return(
        <div className={ "ModelPage_TextTitle " + spaceTop + " " + spaceBottom }>
            <div className="container">
                {
                    props.data.title == "" ? null : <div className="title">{ props.data.title }</div>
                }                
                <div className="text" dangerouslySetInnerHTML={ { __html: props.data.text.replaceAll('&#34;', '"') } } />
                
            </div>
        </div>
    )
}