import { useState, useEffect } from "react";

import './CardIcons.css';

import { GetListPag } from "interface/Page";
import { GetDataPage } from "interface/Data";

export default function ModelPage_CardIcons(props){

    const [ spaceTop, setSpaceTop ]       = useState('');
    const [ spaceBottom, setSpaceBottom ] = useState('');
    
    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(InitialData());

    function InitialData(){
        const newData = GetDataPage('site').filter(item => item.idioma == idioma);
        return newData
    }

    useEffect(()=>{
        if(props.index != 0){            
            if(dataPage[props.index - 1]['type'] == 'Seta'){
                setSpaceTop('Space_Arrow_bottom');
            }
            if(dataPage[props.index + 1]){
                if(dataPage[props.index + 1]['type'] == 'Seta'){
                    setSpaceBottom('Space_Arrow_top');
                }
            }
        }
    }, []);

    return(
        <div className={ "ModelPage_CardIcons " + spaceTop + " " + spaceBottom }>
            <div className="container">
                {
                    props.data.contents.map((elem, index)=>{
                        return(
                            <div className="card_image" key={ index }>
                                <div className="div_img">
                                    <img alt={ "img_" + index } src={ elem.file } className="img" />
                                </div>
                                <div className="text" dangerouslySetInnerHTML={ { __html: elem.text.replaceAll('&#34;', '"') } } />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}