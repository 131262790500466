import { useState, useEffect } from "react";

import './TextPhoto.css';

import { GetListPag } from "interface/Page";
import { GetDataPage } from "interface/Data";

export default function ModelPage_TextPhoto(props){

    const [ spaceTop, setSpaceTop ]       = useState('');
    const [ spaceBottom, setSpaceBottom ] = useState('');
    
    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(InitialData());

    function InitialData(){
        const newData = GetDataPage('site').filter(item => item.idioma == idioma);
        return newData
    }

    function ShowImg(){
        return (
            <div className="div_img">
                <img alt="img" src={ props.data.file } className="img" />
            </div>
        )
    }

    function ShowText(value){
        return(
            <div className={ "div_text " + value }>
                {
                    props.data.title == "" ? null : <div className="title" style={ { textAlign: value } }>{ props.data.title }</div>
                }                
                <div className="text" style={ { textAlign: value } } dangerouslySetInnerHTML={ { __html: props.data.text.replaceAll('&#34;', '"') } } />
            </div>
        )
    }

    useEffect(()=>{
        if(props.index != 0){            
            if(dataPage[props.index - 1]['type'] == 'Seta'){
                setSpaceTop('Space_Arrow_bottom');
            }
            if(dataPage[props.index + 1]){
                if(dataPage[props.index + 1]['type'] == 'Seta'){
                    setSpaceBottom('Space_Arrow_top');
                }
            }
        }
    }, []);

    return(
        props.data.file_align == 'bg_left' || props.data.file_align == 'bg_right' ?
        <div className={ "ModelPage_TextPhoto img_fixed " + spaceTop + " " + spaceBottom } style={ { backgroundImage: 'url("' + props.data.file + '")' } }>
            {
                props.data.color == "not" ? null : <div className="color_bg_img" style={ { backgroundColor: props.data.color } } />
            }
            <div className={ "container text_" + props.data.file_align }>
                <div className="show_img_bg">{ ShowText(props.data.file_align.replaceAll('bg_', '')) }</div>
            </div>
        </div>
        :
        <div className={ "ModelPage_TextPhoto " + spaceTop + " " + spaceBottom } style={ { background: props.data.color == "" ? "#F5F5F5" : props.data.color } }>
            <div className="container show_img_side">
                {
                    props.data.file_align == "right" ? 
                    <>
                        { ShowText('left') }
                        { ShowImg() }
                    </> :
                    <>
                        { ShowImg() }
                        { ShowText('right') }
                    </>
                }
            </div>
        </div>
    )
}