import { useState, useEffect } from "react";

import './Menu.css';
import { SvgMenu } from "components/SvgFile";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

export default function Menu(props){

    const [ idioma, setIdioma ] = useState(GetListPag('idioma'));
    const [ status, setStatus ] = useState(false);

    function OpenIdioma(value){
        let accordion = document.getElementById('div_idioma');
        if(value == true){
            accordion.style.maxHeight = accordion.scrollHeight + "px";
        }else {
            accordion.style.maxHeight = null;
        }
        setStatus(value);
    }
    
    function CloseIdioma(value){
        let accordion = document.getElementById('div_idioma');        
        accordion.style.maxHeight = null;

        props.setLoading(true);
        SetListPag('idioma', value);
        setTimeout(() => {
            props.setLoading(false);
        }, 1200);
        setStatus(false);
    }

    function ShowIdioma(){
        return(
            <div className="div_idioma">
                <img alt="idioma" src={ "./assets/idioma_" + idioma + ".png" } className="img_idioma" onClick={ ()=>{ OpenIdioma(!status) } } />
                <div id="div_idioma" className="list_opt">
                    {
                        idioma == "pt_pt" ? null : <img alt="idioma" src="./assets/idioma_pt_pt.png" className="img_idioma" onClick={ ()=>{ CloseIdioma('pt_pt') } } />
                    }
                    {
                        idioma == "pt_br" ? null : <img alt="idioma" src="./assets/idioma_pt_br.png" className="img_idioma" onClick={ ()=>{ CloseIdioma('pt_br') } } />
                    }
                    {
                        idioma == "en" ? null : <img alt="idioma" src="./assets/idioma_en.png" className="img_idioma" onClick={ ()=>{ CloseIdioma('en') } } />
                    }
                    {
                        idioma == "es" ? null : <img alt="idioma" src="./assets/idioma_es.png" className="img_idioma" onClick={ ()=>{ CloseIdioma('es') } } />
                    }
                </div>
            </div>
        )
    }

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
    }, []);

    useEffect(()=>{
        setIdioma(GetListPag('idioma'))
    }, [idioma]);
    
    return(
        <div className="Menu" id="div_menu">
            <div className="div_contact">
                <div className="">Tás a espera de que para ligar?</div>
                <div className="">
                    <a href="tel:16916126312">916126312</a>
                </div>
            </div>
            <div className="div_logo">
                <img alt="logotipo" src="./assets/footer_01.png" className="logo" />
            </div>
            <div className="div_opt">
                {/* {
                    ShowIdioma()
                } */}
                <SvgMenu className="icons" color="#E7E3CE" />
            </div>
        </div>
    )
}