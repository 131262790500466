export function SvgErro(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z" />
        </svg>
    )
}

export function SvgChekedReturn(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" />
        </svg>
    )
}

export function SvgArrow(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path d="M9 18L15 12L9 6" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export function SvgArrow_2(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path fill={ props.color } d="M2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2A10,10 0 0,0 2,12M4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12M10,17L15,12L10,7V17Z"/>
        </svg>
    )
}

export function SvgArrow_3(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path fill={ props.color } d="M12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22M12,7L7,12H10V16H14V12H17L12,7Z"/>
        </svg>
    )
}

export function SvgStar_1(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path fill={ props.color } d="M5.8 21L7.4 14L2 9.2L9.2 8.6L12 2L14.8 8.6L22 9.2L18.8 12H18C17.3 12 16.6 12.1 15.9 12.4L18.1 10.5L13.7 10.1L12 6.1L10.3 10.1L5.9 10.5L9.2 13.4L8.2 17.7L12 15.4L12.5 15.7C12.3 16.2 12.1 16.8 12.1 17.3L5.8 21M17 14V17H14V19H17V22H19V19H22V17H19V14H17Z"/>
        </svg>
    )
}

export function SvgStar_2(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path fill={ props.color } d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"/>
        </svg>
    )
}

export function SvgClose(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path d="M18 6L6 18" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6 6L18 18" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export function SvgDelete(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path d="M3 6H5H21" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10 11V17" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14 11V17" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export function SvgMenu(props){
    return(
        <svg viewBox="0 0 40 40" fill="none" className={ props.className }>
            <path fill={ props.color } d="M4.80057 0.35791C2.22126 0.35791 0.123114 2.45621 0.123114 5.03537C0.123114 7.61453 2.2204 9.71268 4.80057 9.71268C7.38075 9.71268 9.47803 7.61453 9.47803 5.03537C9.47803 2.45621 7.37974 0.35791 4.80057 0.35791ZM19.9391 0.35791C17.3599 0.35791 15.2616 2.45621 15.2616 5.03537C15.2616 7.61453 17.3599 9.71268 19.9391 9.71268C22.5184 9.71268 24.617 7.61453 24.617 5.03537C24.617 2.45621 22.5193 0.35791 19.9391 0.35791ZM35.3225 9.71283C37.9013 9.71283 40 7.61468 40 5.03551C40 2.45635 37.903 0.35791 35.3225 0.35791C32.7421 0.35791 30.6451 2.45621 30.6451 5.03537C30.6451 7.61453 32.7437 9.71283 35.3225 9.71283ZM4.67833 24.7009C7.25749 24.7009 9.35622 22.6031 9.35622 20.0235C9.35622 17.4438 7.25749 15.3457 4.67833 15.3457C2.09916 15.3457 0 17.444 0 20.0235C0 22.6031 2.09916 24.7009 4.67833 24.7009ZM19.8169 24.7009C22.3956 24.7009 24.4943 22.6031 24.4943 20.0235C24.4943 17.4438 22.3973 15.3457 19.8169 15.3457C17.2368 15.3457 15.1394 17.4438 15.1394 20.0235C15.1394 22.6031 17.2377 24.7009 19.8169 24.7009ZM35.2016 24.7009C37.7813 24.7009 39.8791 22.6031 39.8791 20.0235C39.8791 17.4438 37.7813 15.3457 35.2016 15.3457C32.6219 15.3457 30.5224 17.4438 30.5224 20.0235C30.5224 22.6031 32.6211 24.7009 35.2016 24.7009ZM4.78537 30.2872C2.2062 30.2872 0.107037 32.3849 0.107037 34.9647C0.107037 37.5434 2.2062 39.642 4.78537 39.642C7.36453 39.642 9.46268 37.5444 9.46268 34.9647C9.46268 32.3849 7.36453 30.2872 4.78537 30.2872ZM19.9248 30.2872C17.3454 30.2872 15.2464 32.3849 15.2464 34.9647C15.2464 37.5434 17.3447 39.642 19.9248 39.642C22.5035 39.642 24.6021 37.5444 24.6021 34.9647C24.6021 32.3849 22.5035 30.2872 19.9248 30.2872ZM35.3078 30.2872C32.729 30.2872 30.6303 32.3849 30.6303 34.9647C30.6303 37.5434 32.729 39.642 35.3078 39.642C37.8875 39.642 39.9852 37.5444 39.9852 34.9647C39.9852 32.3849 37.8865 30.2872 35.3078 30.2872Z"/>
        </svg>
    )
}