import Axios from 'axios';

// Request Health
export function Reg_Health(contents, name, email, contact, date, hour, text, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append("current_page", "request_health");

    data.append('name', name);
    data.append('email', email);
    data.append('contact', contact);
    data.append('date', date);
    data.append('hour', hour);
    data.append('text', text);
    
    contents.forEach(element => {
        data.append('question[]', element.text);
        data.append('response[]', element.question);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/request_health.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {      
        switch (response.data) {
            case "Email enviado com sucesso":
                    CallbackSuccess();
                break;
            default:
                    CallbackError();
                break;
        }

    }).catch((error)=>{
        CallbackError();
    })
}

// Send book
export function Reg_SendBook(email, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append("current_page", "send_email");

    data.append('email', email);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/send_email.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {     
        switch (response.data) {
            case "Email enviado com sucesso":
                    CallbackSuccess();
                break;
            default:
                    CallbackError();
                break;
        }

    }).catch((error)=>{
        CallbackError();
    })
}