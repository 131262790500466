// Connection type
export const connectionPage = '.';

// Cookies type
export const cookiesRegister = 'sitemodelo2.fastevolution.com.br/';

// Cookies name
export const cookie_site = 'midiaooh_site';

// week
export const listWeek = [
    {
        "value": "monday",
        "label": "Segunda-feira"
    },
    {
        "value": "tuesday",
        "label": "Terça-feira"
    },
    {
        "value": "wednesday",
        "label": "Quarta-feira"
    },
    {
        "value": "thursday",
        "label": "Quinta-feira"
    },
    {
        "value": "friday",
        "label": "Sexta-feira"
    },
    {
        "value": "saturday",
        "label": "Sábado"
    }   
]
// hour
export const listHour = [
    "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00"
]