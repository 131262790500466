import { useState, useEffect } from "react";

import './CardPhoto.css';

import { GetListPag } from "interface/Page";
import { GetDataPage } from "interface/Data";

export default function  ModelPage_CardPhoto(props){

    const [ spaceTop, setSpaceTop ]       = useState('');
    const [ spaceBottom, setSpaceBottom ] = useState('');
    
    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(InitialData());

    function InitialData(){
        const newData = GetDataPage('site').filter(item => item.idioma == idioma);
        return newData
    }

    useEffect(()=>{
        if(props.index != 0){            
            if(dataPage[props.index - 1]['type'] == 'Seta'){
                setSpaceTop('Space_Arrow_bottom');
            }
            if(dataPage[props.index + 1]){
                if(dataPage[props.index + 1]['type'] == 'Seta'){
                    setSpaceBottom('Space_Arrow_top');
                }
            }
        }
    }, []);

    return(
        <div className={ "ModelPage_CardPhoto " + spaceTop + " " + spaceBottom }>
            <div className="container">
                {
                    props.data.contents.map((elem, index)=>{
                        return(
                            <div className="card_image" key={ index } style={ { backgroundImage: 'url("' + elem.file + '")' } }>
                                <div className="bg_color" />
                                <div className={ index == 0 ? "div_data data_left" : "div_data data_right" }>
                                    <div className="show_data">
                                        <div className={ index == 0 ? "title left" : "title right" }>{ elem.title }</div>
                                        <div className={ index == 0 ? "text left" : "text right" } dangerouslySetInnerHTML={ { __html: elem.text.replaceAll('&#34;', '"') } } />
                                        <div className="btn">{ elem.btn }</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}