import React, { useState, useEffect } from "react";

import { SvgClose } from "components/SvgFile";

import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

import './Health.css';

import TextareaAutosize from 'react-textarea-autosize';

import { Reg_Health } from "services";

import { GetListPag } from "interface/Page";
import { GetDataPage } from "interface/Data";

import { listHour } from "fixedData";

export default function PopUP_Health(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ dataPage, setDataPage ] = useState("");

    const [ idioma, setIdioma ]                 = useState(GetListPag('idioma'));
    const [ hourDisponible, setHourDisponible ] = useState(GetDataPage('unavailable_time'));
    
    const [ stage, setStage ]               = useState(0);
    const [ textQuestion, setTextQuestion ] = useState([]);

    const [ name, setName ]         = useState('');
    const [ email, setEmail ]       = useState('');
    const [ contact, setContact ]   = useState('');
    const [ date, setDate ]         = useState('');
    const [ hour, setHour ]         = useState('');
    const [ text, setText ]         = useState('');
    const [ msgError, setMsgError ] = useState('');

    function InitialData(){
        const newData = GetDataPage('questionnaire').filter(item => item.idioma == idioma);
        return newData[0];
    }

    function HandleData(index, value){
        const newData  = [...textQuestion];
        newData[index]['question'] = value;
        setTextQuestion(newData);
    }

    function CheckedDay(value){   
        let selectDate = new Date(value);
        setDate(value);
        if(selectDate.getDay() == 6){
            setMsgError('Data indisponível!');
        }else {
            setMsgError('');
        }
    }

    function ShowListHour(){     
        const newData = [];   
        let selectDate = new Date(date);
        if(hourDisponible.find(item => item.date == date)){
            const dateSelected = hourDisponible.filter(item => item.date == date);
            listHour.map((elem, index)=>{
                if(selectDate.getDay() == 5){
                    if(index <= 7){
                        if(dateSelected.find(item => item.hour == elem)){
                            newData.push({ "value": elem + " - indisponível", "status": true });
                        }else {
                            newData.push({ "value": elem, "status": false });
                        }
                    }else {
                        newData.push({ "value": elem, "status": false });
                    }
                }else {
                    if(dateSelected.find(item => item.hour == elem)){
                        newData.push({ "value": elem + " - indisponível", "status": true });
                    }else {
                        newData.push({ "value": elem, "status": false });
                    }
                }
            });

        }else {            
            listHour.map((elem, index)=>{
                if(selectDate.getDay() == 5){
                    if(index <= 7){
                        newData.push({ "value": elem, "status": false });
                    }
                }else {
                    newData.push({ "value": elem, "status": false });
                }
            });
        }

        return(            
            <select className="list_hour" onChange={ (e)=>{ setHour(e.target.value) } } value={ hour } required>
                <option value="" disabled={ true }>#</option>
                {                    
                    newData.map((elem, index)=>{
                        return(
                            <option value={ elem.value } key={ index } disabled={ elem.status }>{ elem.value }</option>
                        )
                    })
                }
            </select>
        )
    }

    function CurrentStage(){
        switch (stage) {
            case 0:
                return(
                    <div className="show_data">
                        <div className="popup_text" dangerouslySetInnerHTML={ { __html: dataPage.text ? dataPage.text.replaceAll('&#34;', '"') : null } } />
                        <div className="btn" onClick={ ()=>{ setStage(1); setTextQuestion(dataPage.contents) } }>Iniciar</div>
                    </div>
                );

            case 1:
                return(
                    <div className="show_data">
                        {
                            textQuestion.map((elem, index)=>{
                                return(
                                    <div className="" key={ index }>
                                        <div className="list_data_input">
                                            <div className="popup_text" dangerouslySetInnerHTML={ { __html: elem.text ? elem.text.replaceAll('&#34;', '"') : null } } />
                                        </div>
                                        <div className="list_data_input">
                                            <div className="div_input space_div">
                                                <TextareaAutosize className="input textarea" onChange={ (e)=>{ HandleData(index, e.target.value) } } value={ elem.response } required placeholder="Descreva aqui..." />
                                                <div className="name_input">Resposta</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        
                        <div className="btn" onClick={ ()=>{ setStage(2); } }>
                            Solicitar consulta
                        </div>
                    </div>
                )

            case 2:
                return(
                    <div className="show_data">
                        <div className="list_data_input">
                            <div className="div_input space_div">
                                <input type="text" className="" onChange={ (e)=>{ setName(e.target.value) } } value={ name } required />
                                <div className="name_input">Nome*</div>
                            </div>
                            <div className="div_input space_div">
                                <input type="email" className="" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email } required />
                                <div className="name_input">E-mail*</div>
                            </div>
                            <div className="div_input">
                                <input type="text" className="contact" onChange={ (e)=>{ setContact(e.target.value) } } value={ contact } required />
                                <div className="name_input">Contato*</div>
                            </div>
                        </div>

                        <div className="list_data_input">
                            <div className="div_input">
                                <input type="date" id="date_" className="input_date" onChange={ (e)=>{ CheckedDay(e.target.value); } } value={ date } required />
                                <div className="name_input">Escolha uma data para consulta*</div>
                            </div>
                            {
                                msgError == "" ?
                                    date == '' ? null : 
                                    <div className="div_input">
                                        { ShowListHour() }
                                        <div className="name_input">Horários*</div>
                                    </div> 
                                : <div className="error_date">{ msgError }</div>
                            }
                        </div>

                        <div className="list_data_input">
                            <div className="div_input space_div">
                                <TextareaAutosize className="input textarea" onChange={ (e)=>{ setText(e.target.value) } } value={ text.replace(/<br>/g,'\n') } placeholder="Descreva aqui..." />
                                <div className="name_input">Obervação</div>
                            </div>
                        </div>
                        
                        <div className="list_data_input">
                            <button type="submit" className="btn btn_save" onClick={ ()=>{  } }>Marcar consulta</button>
                        </div>
                    </div>
                )
        }
    }

    function saveData(event){
        event.preventDefault();
        if(msgError == ''){
            modalData.setLoading(true);
            Reg_Health(dataPage.contents, name, email, contact, date, hour, text, CallbackSuccess, CallbackError);
        }else {
            setMsgError('É necessário definir a hora da consulta')
        }
    }

    function CallbackSuccess(){
        modalData.setLoading(false);
        ClosePopUp();
        SetModalData('ReturnResponse', { "page": "ok!" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        modalData.setLoading(false);
        ClosePopUp();
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ClosePopUp(){
        setDataPage("");
        
        setStage(0);
        setTextQuestion([]);

        setName('');
        setEmail('');
        setContact('');
        setDate('');
        setHour('');
        setText('');
        setMsgError('');
        SetModalState('Health', false);
    }

    useEffect(()=>{
        RegisterModalData('Health', setModaldata);
        RegisterModalObserver('Health', setShowPopUp);
    }, []);
    
    useEffect(()=>{
        if(showPopUp == true){
            setDataPage(InitialData());
            document.body.style.overflow = "hidden";

        }else {
            document.body.style.overflow = null;
        }
    }, [showPopUp]);

    useEffect(()=>{
        if(showPopUp == true){
            ShowListHour();
            setHour('');
        }
    }, [date])

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all health">
                        <div className="div_data type_div">
                            <div className="popup_title">{ dataPage.title }</div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <form onSubmit={ saveData } className="content">
                                {
                                    CurrentStage()
                                }
                            </form>
                        </div>
                    </div>
                    <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
